/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class StudentStore {

  loadingMessageList: boolean = false;

  messageList: any = { totalCount: 0, data: [] }
  messageNumber: number = 0;


  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loadingMessageList: observable,
      messageList: observable,
      getMessageList: action,
      readMessage: action,



    });

    this.rootStore = rootStore;
  }

  getMessageList = async (take: number, skip: number) => {

    this.loadingMessageList = true;

    try {
      const res = await Axios.get(`/api/Message/GetMessageList/${take}/${skip}`);
      this.messageList.data = res.data.data.reverse();
      this.messageList.totalCount = res.data.totalCount;
      console.log(`Message List take: ${take} skip: ${skip}: ${res.data}`);
      return Promise.resolve(res.headers);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingMessageList = false;
    }
  }

  readMessage = async (id: number) => {
    try {
      const res = await Axios.get(`/api/Message/ReadMessage/${id}`);
      console.log(`read message id: ${id}: ${res.data}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getNewMessageNumber = async () => {
    try {
      const res = await Axios.get(`/api/Message/GetNewMessageNumber`);
      this.messageNumber = res.data;
      console.log(`Message Number: ${res.data}`);
      return Promise.resolve(res.data);
    } catch(err) {
      return Promise.reject(err);
    }
  }
}
