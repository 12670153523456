exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-enrol-form-tsx": () => import("./../../../src/pages/enrol-form.tsx" /* webpackChunkName: "component---src-pages-enrol-form-tsx" */),
  "component---src-pages-enroll-form-index-tsx": () => import("./../../../src/pages/enroll-form/index.tsx" /* webpackChunkName: "component---src-pages-enroll-form-index-tsx" */),
  "component---src-pages-enroll-form-registration-form-tsx": () => import("./../../../src/pages/enroll-form/registration-form.tsx" /* webpackChunkName: "component---src-pages-enroll-form-registration-form-tsx" */),
  "component---src-pages-exam-exam-attempt-tsx": () => import("./../../../src/pages/exam/exam-attempt.tsx" /* webpackChunkName: "component---src-pages-exam-exam-attempt-tsx" */),
  "component---src-pages-exam-index-tsx": () => import("./../../../src/pages/exam/index.tsx" /* webpackChunkName: "component---src-pages-exam-index-tsx" */),
  "component---src-pages-exam-on-exam-tsx": () => import("./../../../src/pages/exam/on-exam.tsx" /* webpackChunkName: "component---src-pages-exam-on-exam-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-message-overview-tsx": () => import("./../../../src/pages/message/overview.tsx" /* webpackChunkName: "component---src-pages-message-overview-tsx" */),
  "component---src-pages-registration-tsx": () => import("./../../../src/pages/registration.tsx" /* webpackChunkName: "component---src-pages-registration-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-student-index-tsx": () => import("./../../../src/pages/student/index.tsx" /* webpackChunkName: "component---src-pages-student-index-tsx" */),
  "component---src-pages-traineeship-tsx": () => import("./../../../src/pages/traineeship.tsx" /* webpackChunkName: "component---src-pages-traineeship-tsx" */),
  "component---src-pages-verify-email-tsx": () => import("./../../../src/pages/verify-email.tsx" /* webpackChunkName: "component---src-pages-verify-email-tsx" */),
  "component---src-pages-visit-index-tsx": () => import("./../../../src/pages/visit/index.tsx" /* webpackChunkName: "component---src-pages-visit-index-tsx" */),
  "component---src-pages-visit-replacement-tsx": () => import("./../../../src/pages/visit-replacement.tsx" /* webpackChunkName: "component---src-pages-visit-replacement-tsx" */)
}

