/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import { composeImageUrlsInCartItems, ImageSize } from '../utilities/image-url-composer';
import { isNative, isWeb } from '../utilities/platform';
import atob from '../utilities/atob';
import RootStore from './root-store';
import axios from 'axios';
import { USIBackendURL } from '../constants/settings';
const isServer = typeof window === 'undefined';

export default class UserStore {
  get isAuthed() {
    if (typeof window !== "undefined" && localStorage.USER_TOKEN && localStorage.USER_INFO)
      return true;
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  set isAuthed(value) {
    console.log('Deleting token...');
    if (!value && isWeb) {
      delete localStorage.USER_TOKEN;
      delete localStorage.USER_INFO;
    }
  }

  userToken: string | null = null;

  loading: boolean = false;

  loadingTraineeshipList: boolean = false;

  loadingVisitList: boolean = false;

  loadingVisitTable: boolean = false;

  loadingVisitModal: boolean = false;

  loadingFile: boolean = false;

  verifyingUSI: boolean = false;

  studentProfile: any = {};

  visitList: any = { totalCount: 0, data: [] };

  traineeshipVisitList: any = { totalCount: 0, data: [] };

  visitHistoryList: any = { totalCount: 0, data: [] };

  visitCurrentList: any = { totalCount: 0, data: [] };

  visitChangeList: any = [];

  visitOverlapList: any = [];

  organisationList: any = { totalCount: 0, data: [] };

  msgNumber: number = 0;


  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      isAuthed: computed,
      userToken: observable,
      loading: observable,
      loadingTraineeshipList: observable,
      loadingVisitList: observable,
      loadingVisitTable: observable,
      loadingFile: observable,
      loadingVisitModal: observable,
      verifyingUSI: observable,
      visitList: observable,
      visitOverlapList: observable,
      traineeshipVisitList: observable,
      visitChangeList: observable,
      organisationList: observable,
      visitCurrentList: observable,
      studentProfile: observable,
      msgNumber: observable,
      signIn: action,
      signOut: action,
      sendResetPasswordEmail: action,
      getStudentProfile: action,
      updateStudentProfile: action,
      getVisitList: action,
      getVisitListByTraineeshipId: action,
      getUploadedFile: action,
      getChangeRequestListByVisitId: action,
      updateChangeRequestStatus: action,
      createVisitChangeRequest: action,
      getOrganisationListbyGrouId: action,
      setVisitTableLoading: action,
      getNewMessageNumber: action,
      verifyEmail: action,
      checkTimeAvailability: action,
      verifyUSI: action,
      updateUSI: action,
    });

    this.rootStore = rootStore;
  }

  setVisitTableLoading = (flag: boolean) => {
    this.loadingVisitTable = flag;
  }

  signIn = async (userModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Auth/SignIn`, userModel);
      const token = `Bearer ${res.data.token}`;
      console.log(res.data);
      this.userToken = res.data.token;
      localStorage.USER_TOKEN = token;
      let userInfo = {
        title: res.data.title,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        email: res.data.email,
        collegeId: res.data.collegeId,
        birthDate: res.data.birthDate,
        middleName: res.data.middleName,
        usi: res.data.usiid,
      }
      localStorage.USER_INFO = JSON.stringify(userInfo);
      localStorage.CALENDAR = JSON.stringify({
        semester: res.data.currentSemesterId,
        startDate: res.data.startDate,
        endDate: res.data.endDate,
      });
      return Promise.resolve(res.data);
    } catch (err) {
      this.isAuthed = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  signOut = (disableAutoDirect?: boolean) => {
    this.isAuthed = false;
    this.userToken = "";
    if (!disableAutoDirect) window.location.href = "/";
  };

  verifyEmail = async () => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Auth/VerifyEmail`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  resendVerifyEmail = async (email: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Auth/ResendVerifyEmail/${email}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  sendResetPasswordEmail = async (email: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Auth/SendResetPasswordEmail`, email);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getStudentProfile = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Profile/GetStudentProfile/`);
      this.studentProfile = res.data;
      console.log("Student Profile", toJS(this.studentProfile));
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateStudentProfile = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Profile/UpdateStudentProfile`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  /** 
  * @deprecated replace by get visit list by traineeship
  */
  getVisitList = async (isHistory: boolean, take: number, skip: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Visit/GetVisitList/${isHistory}/${take}/${skip}`);
      if (history) this.visitHistoryList = res.data;
      else this.visitCurrentList = res.data;
      console.log("Visit List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getUploadedFile = async (visitId: any, name: string) => {
    this.loadingFile = true;
    try {
      const res = await Axios.get(`api/File?visitId=${visitId}&name=${name}`
        , { responseType: "blob" });
      console.log("Upload File List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err);
      return Promise.resolve(err);
    } finally {
      this.loadingFile = false;
    }
  }

  //june

  getChangeRequestListByVisitId = async (id: number) => {
    this.loadingVisitTable = true;
    try {
      const res = await Axios.get(`/api/Visit/GetChangeRequestListByVisitId/${id}`)
      console.log("Visit Change List", res.data);
      this.visitChangeList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingVisitTable = false;
    }
  }

  updateChangeRequestStatus = async (id: number, status: boolean) => {
    this.loadingVisitTable = true;
    try {
      const res = await Axios.put(`/api/Visit/updateChangeRequestStatus/${id}/${status}`)
      return Promise.resolve(res.data)
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingVisitTable = false;
    }
  }

  createVisitChangeRequest = async (changeModel: any) => {
    this.loadingVisitTable = true;
    try {
      const res = await Axios.post('/api/Visit/CreateVisitChangeRequest', changeModel);
      return Promise.resolve(res.headers);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingVisitTable = false;
    }
  }

  getOrganisationListbyGrouId = async (id: number) => {
    this.loadingVisitModal = true;
    try {
      const res = await Axios.get(`/api/Visit/GetOrganisationListByGroupId/${id}`);
      this.organisationList.totalCount = res.data.length
      this.organisationList.data = res.data
      // console.log("Location ID by Group Id this.orgaisaionList", toJS(this.organisationList.data))
      // console.log("Location ID by Group Id res.data", res.data)
      return Promise.resolve(res.data)

    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingVisitModal = false;
    }
  }

  getVisitListByTraineeshipId = async (traineeshipId: number) => {
    this.loadingVisitList = true;
    try {
      const res = await Axios.get(`/api/Visit/GetVisitListByTraineeshipId/${traineeshipId}`);
      this.traineeshipVisitList.totalCount = res.data.length
      this.traineeshipVisitList.data = res.data
      console.log("visit list by traineeship Id: ", res.data)
      return Promise.resolve(res.data)
    } catch (err) {
      return Promise.reject(err)
    } finally {
      this.loadingVisitList = false;
    }
  }

  getNewMessageNumber = async () => {
    try {
      const res = await Axios.get(`/api/Message/GetNewMessageNumber`);
      this.msgNumber = res.data;
      console.log("MSG Number", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  checkTimeAvailability = async(checkTime: any) => {
    try {
      const checkTimeToList = [checkTime];
      const res = await Axios.post(`/api/Visit/CheckTimeAvailability`, checkTimeToList, {headers: {"Content-Type": "application/json"}});
      this.visitOverlapList = res.data;
      console.log(res.data);
      return Promise.resolve(res.data);
    } catch(err) {
      return Promise.reject(err);
    } finally {

    }
  }

  verifyUSI = async (reqModel: any) => {
    this.verifyingUSI = true;
    try {
      const res = await axios.post(`${USIBackendURL}/api/USI/VerifyUSI`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.verifyingUSI = false;
    }
  }

  updateUSI = async (reqModel: any) => {
    try {
      const res = await Axios.post(`/api/StudentEnroll/UpdateUSI`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
    }
  }
}
