const login = {
  SIGN_IN: 'Sign In',
  SIGN_IN_NOW: 'Sign In Now',
  WELCOME_TO_THE_SYSTEM: 'Welcome to The System',
  FORGOT_PASSWORD: 'Forgot Password',
  USERNAME: 'Username',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Your Password',
  TWO_PASSWORDS_NOT_MATCH: 'Passwords Do Not Match',
  INCORRECT_USERNAME_PASSWORD: 'Incorrect Username/Password',
  EMAIL: 'Email',
  CONFIRM_EMAIL: 'Confirm Email',
  TWO_EMAILS_NOT_MATCH: 'Emails Do Not Match',
  INVALID_EMAIL: 'Invalid Email',
  SEND_VERIFICATION_EMAIL: 'Send Verification Email',
  VERIFICATION_EMAIL_SENT: 'Verification Email Sent',
  RESET_PASSWORD: 'Reset Password',
  RESET_YOUR_PASSWORD: 'Reset Your Password',
  RESET_PASSWORD_FAILED: 'Reset Password Failed',
  VERIFY_URL_LINK: 'Verify URL Link',
  SIGN_OUT: 'Sign Out',
  SIGN_OUT_CHECK: 'Are you wish to you sign out now',
  VALIDATING_USER_CREDENTIAL: 'Validating User Credentials',
  BYE: 'Bye',
  WELCOME_TO_AUOKKA: 'Welcome to Auokka School Learning Management System',
  EMAIL_NOT_EXIST: 'Email does not exist',
  RESET_PASSWORD_NOW: 'Reset Password Now',
  NEW_PASSWORD: 'New Password',
  RESET_NOW: 'Reset Now',
  YOUR_EMAIL: 'Your Email',
  SEND_EMAIL: 'Send Email',
  BACK_TO_LOGIN: 'Back to Login',
  ENTER_YOUR_EMAIL: 'Enter Your Account Email to Get Password Reset Email',
  STUDENT_REGISTRATION: 'Trainee Registration',
  REGISTER_AN_ACCOUNT: 'Register an Account',
}

const menu = {
  HI: "Hi",
  DASHBOARD: 'Dashboard',
  STUDENT: 'Trainee',
  TEACHER: 'Teacher',
  ACADEMIC: 'Academic',
  QUESTION: 'Question',
  EXAM: 'Exam',
  CALENDAR: 'Calendar',
  GROUP: 'Group',
  SUBJECT: 'Subject',
  SUBJECT_CLASS: 'Subject Class',
  DOMAIN: 'Domain',
  QUESTION_TYPE: 'Type',
  QUESTION_CATEGORY: 'Question Category',
  KNOWLEDEGE_POINT: 'Knowledge Point',
  OVERVIEW: 'Overview',
  ENROLLMENT: 'Enrollment',
  EXAM_SETTING: 'Exam Setting',
  EXAM_QUESTIONS: 'Exam Questions',
  ORGANISATION: 'Organisation',
  VISIT_LIST: 'Visit List',
}

const dashboard = {
  MSG_LIST: 'Message List',
  TYPE: 'Type',
  DESCRIPTION: 'Description',
  SENDER: 'Sender',
  RECEIVER: 'Reciever',
  SENT_TIME: 'Sent Time',
  ACTION: 'Action',
  VIEW_DETAIL: 'View Detail',
  MSG_DETAIL: 'Message Detail',
  STUDENTS: 'Trainees',
  TEACHERS: 'Trainers',
  ORGANISATIONS: 'Organisations',
  TEACHER_VISITS: 'Teacher Visits',
  ORGANISATION_VISITS: 'Organisation Visits',
  LAST_YEAR: 'Last Year',
}

const yearLevel = {
  MODULE_B: ' Module',
  LIST_B: ' List',
  ADD_A: 'Add ',
  DESCRIPTION_B: ' Description',
  UPDATE_A: 'Update ',
  CONFIRM_A: 'Confirm ',
  CREATION: 'Creation',
  UPDATE_NOW: 'Update Now',
  CREATE_NOW: 'Create Now',
  DELETE_A: 'Delete ',
  CONFIRMATION: 'Confirmation',
  CONFIRM_DELETE: 'Confirm Delete',
  DELETE_NOW: 'Delete Now',
  DELETE_CHECK: 'Are you sure you want to delete this record',
  DELETE_INFO_YEAR_LEVEL: 'All relevant data (eg. Subject, Domain etc.) will also be deleted',
  EDIT: 'Edit',
  UPDATE: 'Update',
}

const subject = {
  SUBJECT_CODE: 'Subject Code',
  SETTING_B: ' Setting',
  NOT_SELECTED: 'Not Selected',
  DELETE_INFO_SUBJECT: 'All relevant data (eg. Subject etc.) will also be deleted'
}

const subjectClass = {
  CLASS_CODE: 'Class Code',
  CLASS_IDENTIFIER: 'Class Identifier',
  DELETE_INFO_SUBJECT_CLASS: 'All relevant data will also be deleted'
}

const domain = {
  CODE_B: ' Code',
  DELETE_INFO_DOMAIN: 'All relevant data (eg. Subject etc.) will also be deleted',
  LINK_SUBJECTS: 'Link with Subjects',
  LINK_DOMAIN_SUBJECT: 'Link Domain with Subjects',
  STEP_1_SELECT: 'Step 1: Select subjects from the list',
  STEP_2_EDIT_SELECT: "Step 2: Edit selected subjects' compulsory status",
  LINK_CONFIRM: 'Link Confirm',
  LINK_NOW: 'Link Now',
  TOGGLE_COMPULSORY_STATUS: 'Toggle Compulsory Status (On = Compulsory Subject, Off = Not Compulsory Subject)',
}

const question = {
  OVERVIEW_B: ' Overview',
  CATEGORY: 'Category',
  DIFFICULTY: 'Difficulty',
  VIEW_OPTIONS: 'View Options',
  QUESTION_TYPE: 'Question Type',
  NO_QUESTION_CAT_FOUND: 'No Question Categories Found',
  NO_KNW_PT_FOUND: 'No Knowledege Point Found',
  DELETE_INFO_QUESTION: 'All relevant data (eg. Trainee Response, Mark etc) will also be deleted',
  DETAIL: 'Detail',
  QUESTION_OPTIONS: 'Question Options',
  OPTION: 'Option',
  OPTION_DETAIL: 'Option Detail',
  IS_CORRECT: 'Is Correct',
  CORRECT_ANSWER: 'Correct Answer',
  DELETE_INFO_EXAM: 'All relevant data (eg. Exam etc.) will also be deleted',
  SEARCH_SUBJECT: 'Search Subject',
  SUBJECT_COUNT: 'Subject Count',
}

const questionType = {
  TYPE: 'Type',
  DELETE_INFO_QUESTION_TYPE: 'All relevant data (eg. Questions etc.) will also be deleted'
}

const questionCat = {
  QUESTION_CATEGORY: 'Question Category',
  DELETE_INFO_QUESTION_CAT: 'All relevant data (eg. Questions etc.) will also be deleted',
}

const knowledgePoint = {
  DELETE_INFO_KNOWLEDGE_PT: 'All relevant data (eg. Questions etc.) will also be deleted',
}

const exam = {
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
  SETTING: 'Setting',
  TIME_LIMIT: 'Time Limit',
  DISPLAY_KNOWLEGE_PT: 'Display Knowledge Points',
  ENABLE_PARTIAL_MARK_IN_MP: 'Enable Partial Marks in MPs',
  ENABLE_SAME_QUESTION_EXAM: 'Enable Same Question Exam',
  INFINITE_ATTEMPTS_ALLOWED: 'Infinite Attempts Allowed',
  ATTEMPT_ALLOW: 'Attempt Allow',
  DELETE_INFO_EXAM: 'All relevant data will also be deleted',
}

const examQuestions = {
  SETTING_A: 'Setting ',
  MARK_B: ' Mark',
  MARKS: ' Marks',
  MARK: 'Mark',
  QUANTITY: 'Quantity',
  NO_SETTING_FOUND: 'No Setting Found',
  EDIT_QUESTIONS: 'Edit Questions',
  QUESTION_REPO_FOR_EXAM: 'Question Repository for Exam: ',
  SAVE_CONFIRM: 'Save Confirm',
  CHANGE_SAVE: 'Change & Save',
  NUMBER_SELECTED_QUESTIONS_EQUAL_TO_QUANTITY_CHECK: 'The number of selected questions must equal to the configured quantity value ',
  PLS_ADJUST_QUANTITY: 'Please adjust your question quantity or adjust setting quantity configuration in exam setting page.',
  QUESTIONS: 'Questions',
  SELECTED_B: ' Selected',
}

const examSetting = {
  MANAGE_STUDENT_ACCESS: 'Manage Trainee Access',
  GENERATE_QUESTIONS: 'Generate Questions',
  GENERATED_QUESTIONS: 'Generated Questions',
  MARK_PER_QUESTION: 'Mark Per Question',
  DELETE_INFO_EXAM_SETTING: 'All relevant data (eg. Exam data etc.) will also be deleted',
  STUDENT_ACCESS: 'Trainee Access',
  SELECT_ALL: 'Select All',
  UNSELECT_ALL: 'Unselect All',
  STUDENTS_B: ' Trainees',
  STUDENT_B: ' Trainee',
}

const teacher = {
  TEACHER_PROFILE: 'Teacher Profile',
  BOD: 'Birth Date',
  TITLE: 'Title',
  MIDDLE_NAME: 'Middle Name',
  USER_ROLE: 'User Role',
  DELETE_INFO_TEACHER: 'All relevant data (eg. Subject Class etc.) will also be deleted',
  NAME: 'Name',
}

const student = {
  STUDENT_PROFILE: 'Trainee Profile',
  DELETE_INFO_STUDENT: 'All relevant data (eg. Subject Class etc.) will also be deleted',
  ENROLLMENT: 'Enrollment',
  STUDENT_SINGLE: 'Student',
  ENROLL_STUDENT_SEMESTER: 'Enroll Student Semester',
  ENROLLED_SEMESTERS: 'Enrolled Semesters',
  VIEW_CLASS: 'View Class',
  DELETE_INFO_ENROLLMENT: 'All relevant data (eg. Student etc.) will also be deleted',
  STUDENT_SEMESTER: 'Student Semester',
  ENROLLED_CLASSES: 'Enrolled Classes',
  ENROLL_CLASS: 'Enrol Class',
  CLASS_IDENTIFIER: 'Class Identifier',
  NO_ENROLLMENTS_FOUND: 'No Enrollments Found',
  ENROLLED_COURSE_INFO: 'Enrolled Course Information',
  REMOVE: 'Remove',
  UNIT_CODE: 'Unit Code',
  COMPULSORY: 'Compulsory',
  SUBJECT_IS_ENROLLED: 'This Subject is enrolled',
  ADD_NOW: 'Add Now',
  DELETE_INFO_SUBJECT_ENROLLMENT: 'All relevant data (eg. Enrolled Class etc.) will also be deleted',
  NO_RESULT_FOUND: 'No Result Found',
  NO_STUDENT_SELECTED: 'No Student Selected',
  NO_SUBJECT_FOUND: 'No Subject Found',
}

const calendar = {
  YEAR: 'Year',
  SCHOOL_YEAR: 'School Year',
  VIEW_SEMESTERS: 'View Semeseter',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  SCHOOL_SEMESTER: 'School Semester',
  SEMESTER: 'Semester',
  VIEW_TERMS: 'View Terms',
  SCHOOL_TERM: 'School Term',
  TERM: 'Term',
  VIEW_TERM_DATE: 'View Term Date',
  SCHOOL_TERM_DATES: 'School Terms Dates',
  TERM_DATE: 'Term Date',
  SEMESTER_INT: 'Semester Int',
  TERM_INT: 'Term Int',
}

const group = {
  ORGANISATION: 'Organisation',
  ORGANISATION_NAME: 'Organisation Name',
  VIEW_VISITS: 'View Visit',
  VIEW_STUDENTS: 'View Students',
  STUDENT_IN_ORGANISATION: 'Student in Organisation',
  VISIT: 'Visit',
  VISITS: 'Visits',
  VISIT_NAME: 'Visit Name',
  UPDATED_TIME: 'Updated Time',
  ADDRESS: 'Address',
  TEL: 'Tel',
  MOBILE: 'Mobile',
  LONGITUDE: 'Longitude',
  LATITUDE: 'Latitude',
  CONTACT_PERSON: 'Contact Person',
  STUDENT_LIST: 'Student List',
  STUDENTS_SINGLE: 'Students',
  TEACHER_SINGLE: 'Teacher',
  SELECT_STUDENT: 'Select Student',
  NO_STUDENTS_FOUND_PLEASE_ADD_STUDENT_ORG: 'No students found, please add students to this organisation first!',
  CLEAR: 'Clear',
  TIME_START_IN: 'Time Start In',
  OVER_8_HOURS_WARNING: 'Please be aware above time slot duration is over 8 hours!',
  ENABLE_RECURRING_VISIT: 'Enable Recurring Visit',
  SKIP_WEEKENDS: 'Skip Weekends',
  RECURRENCE: 'Recurrence',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  REPEAT_FOR: 'Repeat for',
  TIMES: 'times',
  OF_EVERY: 'of every',
  DAY_S: 'day(s)',
  WEEK_S: 'week(s)',
  MONTH_S: 'month(s)',
  OVERWRITE_WARNING: 'Tip: Generate new time slots will resulted in overwrite the old generated timeslots.',
  GENERATE_TIME_SLOTS: 'Generate Time Slots',
  NO_TIME_SLOTS: 'No time slots created yet!',
  UPDATE_VISIT: 'Update Visit',
  CREATE_VISIT: 'Create Visit',
  SCHEDULE_TIME_SLOTS: 'Schedule Time Slots',
  TIME_SLOTS: 'Time Slots',
  GROUP_NAME: 'Group Name',
  VIEW_ORG: 'View Organisation',
  DELETE_INFO_GROUP: 'All relevant data (eg. Organisation etc.) will also be deleted',
  VISITS_BY_ORG: 'Visits By Organisation',
  CLEAR_ALL_MAKER: 'Clear All Marker Window',
  APPLY: 'Apply',
  VIEW: 'View',
  CREATE_TIME: 'Create Time',
  STAFF_NAME: 'Staff Name',
}

const notify = {
  UPDATED_B: ' Updated',
  CREATED_B: ' Created',
  DELETED_B: ' Deleted',
  UPDATE_FAILED: 'Update failed! Please contact network admin!',
  CREATE_FAILED: 'Create failed! Please contact network admin!',
  DELETE_FAILED: 'Delete failed! Please contact network admin!',
  NO_CLASS_SELECTED_FOR_AT_LEAST_ONE_SUBJECT: 'No class selected for at least one subject is detected',
  NO_SUBJECTS_IS_FOUND_IN_SELECTED_DOMAIN: 'No subject is found in the selected domain',
  EXAM_SUBMITTED: 'Exam Submitted!',
  EXAM_SUBMIT_FAILED: 'Exam Submit Failed! Please contact teacher/network admin ASAP!',
  VERIFICATION_EMAIL_SENT_FAILED: 'Failed to sent verification email! Please contact network admin!',
}

const enrolForm = {
  REGISTRATION: 'Registration',
  PERSONAL_INFORMATION: 'Personal Information',
  ADMISSION_QUALIFICATIONS: 'Admission Qualifications',
  CREDIT_TRANSFER: 'Credit Transfer',
  ENTER_YOUR_FULL_NAME: 'Enter Your Full Name',
  SINGLE_NAME_ONLY: 'Single Name Only',
  TICK_BOX_IF_NAME_IN_BELOW_FORMAT: '(Tick this box if you have one name only that cannot be written in the following format. Enter your single in the Family Name section)',
  FAMILY_NAME_SURNAME: 'Family Name (Surname)',
  FIRST_GIVEN_NAME: 'First Given Name',
  SECOND_GIVEN_NAME_MIDDLE: 'Second Given Name (Middle)',
  GENDER: 'Gender',
  MALE: 'Male',
  FEMALE: 'Female',
  PREFER_NOT_TO_SAY: 'Prefer Not to Say',
  SELECT: 'Select',
  UNIQUE_STUDENT_IDENTIFIER: 'Unique Student Identifier',
  CONTACT_DETAILS: 'Contact Details',
  HOME: 'Home',
  WORK_PHONE: 'Work Phone',
  PHONE: 'Phone',
  ALTERNATIVE_EMAIL: 'Alternative Email Address (Optional)',
  PREFERRED_CONTACT_METHOD: 'Preferred Contact Method',
  BUILDING_PROPERTY_NAME: 'Building/Propoerty Name',
  STREET_NO_BUILDING_NUMBER: 'Street No./Unit & Building No.',
  STREET_NAME: 'Street Name',
  SUBURB: 'Suburb',
  STATE: 'State',
  POSTCODE: 'Postcode',
  POSTAL_ADDRESS_IF_DIFFERENT_FROM_ABOVE: 'Postal Address (if different from above)',
  EMERGENCY_CONTACT_DETAILS: 'Emergency Contact Details',
  RELATIONSHIP_TO_YOU: 'Relationship to You',
  FORM: 'Form',
  ABORIGINAL_TS_ISLANDER: 'Are you of Aboriginal or Torres Strait Islander origin',
  YES_ABORIGINAL: 'Yes, Aboriginal',
  YES_TORRES_ISLANDER: 'Yes, Torres Islander',
  YES_BOTH_ABORIGINAL_TORRES_ISLANDER: 'Yes, both Aboriginal and Torres Islander',
  IN_WHICH_COUNTRY_WERE_YOU_BORN: 'In which country were you born',
  SPEAK_LANG_OTHER_THAN_ENG: 'Do you speak a language other than English at home',
  IF_MORE_THAN_ONE_LANG: 'If more than one language, indicate the one that is spoken most often',
  OTHER_PLS_SPECIFY: 'Other (please specify)',
  COUNTRY: 'Country',
  YEAR_ARRIVED_IN_AU: 'Year arrived in Australia',
  NO_ENG_ONLY: 'No, English Only',
  YES_OTHER: 'Yes, other (please specify)',
  LANG: 'Language',
  HIGHEST_COMPLETED_SCHOOL_LEVEL: 'Highest completed school level',
  YEAR_12_EQUIVALENT: 'Year 12 or equivalent',
  YEAR_11_EQUIVALENT: 'Year 11 or equivalent',
  YEAR_10_EQUIVALENT: 'Year 10 or equivalent',
  YEAR_9_EQUIVALENT: 'Year 9 or equivalent',
  YEAR_8_OR_BELOW: 'Year 8 or below',
  NEVER_ATTEND_SCHOOL: 'Never attend school',
  STILL_ENROLLED_IN_SECONDARY_SENIOR_SECONDARY_EDUCATION: 'Are you still enrolled in Secondary or Senior secondary education',
  ENROLLED_YEAR: 'Enrolled Year',
  SUCCESSFULLY_COMPLETED_ANY_FOLLOWING: 'Have you successfully completed any of the following? (If yes, tick applicable boxes)',
  ADVANCE_DIPLOMA: 'Advanced Diploma & Associate Degree',
  BACHELOR_OR_HIGHER: "Bachelor's degree or Higher Degree",
  CERT_ONE: 'Certificate I',
  CERT_TWO: 'Certificate II',
  CERT_THREE: 'Certificate III (or trade certificate)',
  CERT_FOUR: 'Certificate IV (or advanced certificate/technician)',
  DIPLOMA: 'Diploma (or associate diploma)',
  OTHER_EDUCATION: 'Other education (including certificate or overseas qualifications not listed)',
  CURRENT_EMPLOYMENT_STATUS: 'Of the following categories, which BEST describes your current employment status?',
  EMPLOYMENT_STATUS_DEF: 'For casual, seasonal, contract and shift work, use the current number of hours worked per week to determine whether full time (35hours or more per week) or part time employed (less than 35 hours per week)',
  FULL_TIME_EMPLOYEE: 'Full-time employee',
  PART_TIME: 'Part-time employee',
  SELF_EMPLOYED: 'Self-employed - not employing others',
  SELF_EMPLOYED_EMPLOYING_OTHERS: 'Self-employed - employing others',
  EMPLOYED: 'Employed - unpaid worker in a family business',
  UNEMPLOYED_SEEK_FULL_TIME: 'Unemployed - seeking full-time work',
  UNEMPLOYED_SEEK_PART_TIME: 'Unemployed - seeking part-time work',
  NOT_EMPLOYED: 'Not employed - not seeking employment',
  CONSIDER_YOURSELF_HAVING_DISABILITY_IMPAIRMENT_OR_LONG_TERM_CONDITION: 'Do you consider yourself as having a disability impairment or long-term condition?',
  SELECT_AREAS_OF_DISABILITY: 'If you select YES, indicating the presence of a disability, impairment or long-term condition, please select the area(s) in the following list',
  HEARING_DEFAULT: 'Hearing/deaf',
  LEARNING: 'Learning',
  ACQUIRED_BRAIN_IMPAIREMENT: 'Acquired brain impairement',
  PHYSICAL: 'Physical',
  INTELLECTUAL: 'Intellectual',
  MENTAL_ILLNESS: 'Mental illness',
  MEDICAL_CONDITION: 'Medical condition',
  VISION: 'Vision',
  OTHER: 'Other',
  RECIEVE_ADVICE_ON_SUPPORT_SERVICES: 'If Yes to previous question, would you like to recieve advice on support services which may assist you?',
  CITIZEN_PASSPORT_VISA_INFO: 'Citizen/passport visa information citizen status',
  AUSTRALIAN_CITIZEN_OR_ELIGIBLE_RESIDENT: 'Australian citizen or eligible resident (evidence required)',
  OVERSEA_RESIDENT_PASSPORT: 'Oversea resident passport number',
  PASSPORT_NUMBER: 'Passport Number',
  VISA_EXPIRY_DATE: 'Visa Expiry Date',
  VISA_TYPE: 'Visa Type',
  AUS_AID_DEFENCE: 'Aus. Aid Defence',
  HIGHER_EDUCATION: 'Higher Education',
  NOT_AWARD: 'Not Award',
  POSTGRADUATE_RESEARCH: 'Postgraduate Research',
  SCHOOLS: 'Schools',
  VOCATIONAL_EDUCATION_TRAINING: 'Vocational Education & Training',
  INDEPENDENT_ELICOS: 'Independent ELICOS',
  HOW_YOU_REFERRED_BENCHMARK_COLLEGE: 'Please indicate how you were referred to Benchmark College',
  APPRENTICESHIP_CENTRE: 'Apprenticeship Centre',
  EMPLOYMENT_SERVICE_PROVIDER: 'Employment Service Provider (ESP)',
  MY_EMPLOYER: 'My Employer',
  DES: 'Disability Employment Service (DES)',
  ITAB: 'Industry Training Advisory Board (ITAB)',
  STATE_TRAINING_SERVICES: 'State Tranining Services',
  RTOP: 'Another Training Provider (RTO)',
  ESP_DES_NAME: 'ESP/DES Name',
  JOB_SEEKER_IDENTIFICATION_NUMBER: 'Job Seeker Identification Number',
  METHOD_OF_PAYMENT: 'Method of payment',
  SELF: 'Self',
  VET_STUDENT_LOANS: 'VET Student Loans',
  PAYMENT_PLAN: 'Payment Plan',
  MAIN_REASON_DOING_THIS_COURSE: 'Which best describes your main reason for doing this course',
  TO_GET_JOB: 'To get a job',
  DEVELOP_EXISTING_BUSINESS: 'To develop my existing business',
  TRY_DIFFERENT_CAREER: 'To try for a different career',
  BETTER_JOB_PROMOTION: 'To get a better job or promotion',
  REQUIREMENT_OF_JOB: 'It was a requirement of my job',
  PERSONAL_INTEREST_SELF_DEV: 'For personal interest or self development',
  GET_SKILL_COMMUNITY_VOLUNTARY_WORK: 'To get skills for community/voluntary work',
  START_OWN_BUSINESS: 'To start my own business',
  EXTRA_SKILL_MY_JOB: 'I wanted extra skills for my job',
  GET_IN_ANOTHER_COURSE: 'To get into another course of study',
  OTHER_REASONS: 'Other reasons',
  RECOGNITION_PRIOR_LEARNING_CREDIT_TRANSFER: 'Recognition of Prior Learning and Credit Transfer',
  APPLY_FOR_RECOGNITION_PRIOR_LEARNING: 'Do you want to apply for Recognition of Prior Learning',
  APPLY_CREDIT_TRANSFER: 'Do you want to apply for Credit Transfer',
  IF_YES_CERT_DOC_REQUIRED_HINT: 'If yes, certification (such as a transcript) must be attached and Verfication Consent & Credit Transfer Form. For Credit Transfer the Verification Consent & Credit Transfer form available from',
  IF_YES_CERT_DOC_REQUIRED_HINT_CONTD: 'or a Benchmark College representive, call 1800 286 916.',
  FOR_ENROLLED_VET_STUDENT_LOAN: 'For those enrolling in a VET Student Loans enabled program (approved Diploma courses only)',
  HAVE_YOU_COMPLETED_Y12: 'Have you completed Year 12',
  PROVIDE_SUBURB_POSTCODE_LIVED_IN_COMPLETED_Y12: 'Provide suburb and postcode where you lived when completing Year 12',
  YEAR_COMPLETED_Y12: 'Year completed Year 12',
  ACCESS_FEE_HELP_FOR_HIGHER_COURSES: 'Have you previously accessed FEE-HELP for any VET or Higher Education courses',
  IF_YES_CHESSN: 'Your Commonwealth Student Number (CHESSN)',
  NOTE: 'Note',
  USI_HELP_CONTENT: 'From 1 January, Benchmark College can be prevented from issuing you with a nationality recoginised VET qualification or statement of attachment when you complete your course if you do not have a Unique Student Identifier (USI). In addition, we are required to include your USI in the data we submit to NCVER. If you have not yet obtained a USI, apply for it directly at ',
  USI_HELP_CONTENT_CONTD: 'on computer or mobile device.',
  WHAT_IS_USUAL_RESIDENCE: 'What is the address of your usual residence',
  ADDRESS_HELP_CONTENT: "Please provide the physical address (street number and name not post office box) where you usually reside rather than any temporary address at which you reside for training, work or other purposes before returning to your home. If you are from a rural area use the address from your state or territory's 'rural property addressing' or 'numbering' system as your residential street address. Building/property name is the official place name or common usage name for an address site, including the name of a building, Aboriginal community, homestead, building complex, agricultural property, park or unbounded address site.",
  ADMISSION_HELP_CONTENT: 'The following information is for The Australian Vocational Education and Training Management Information Statistical Standard (AVETMISS) and where applicable, the VET Student Loans data collection. This information is required for the collection and analysis of vocational education and training throughout Australia.',
  COMPLETE_NEXT: 'Save & Next',
  SUBMIT_NOW: 'Submit Form',
}

export default {
  translation: {
    ...login,
    ...menu,
    ...dashboard,
    ...subject,
    ...subjectClass,
    ...domain,
    ...question,
    ...questionType,
    ...questionCat,
    ...knowledgePoint,
    ...exam,
    ...examQuestions,
    ...examSetting,
    ...teacher,
    ...yearLevel,
    ...student,
    ...calendar,
    ...group,
    ...notify,
    ...enrolForm,
    lang: 'en-AU',
    GO_BACK: 'Go Back',
    WELCOME: 'Welcome',
    CANCEL: 'Cancel',
    PLEASE_CONTACT_ADMIN: 'Please Contact Admin',
    SHOW_ALL_SUBJECTS: 'Show All Subjects',
    SHOW_ONLY_ENGLISH_MATH: 'Show Only English & Maths',
    YEAR_LEVEL: 'Year Level',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    SURNAME: 'Surname',
    ERROR: 'Error',
    PLEASE_FILL_IN_ALL_FIELDS: 'Please Fill in All Fields',
    NO: 'No',
    YES: 'Yes',
    TODAY: 'Today',
    PREV: 'Prev',
    NEXT: 'Next',
    ABSENT_BETWEEN: 'Absent Between',
    CURRENT: 'Current',
    REMINDER: 'Reminder',
    VIEW_ALL: 'View All',
    VISIT_HISTORY: 'Visit History',
    NO_HISTORY_FOUND: 'No History Found',
    NO_MARKED: 'No Marked',
    NO_FEEDBACK_YET: 'No Feedback Yet',
    NO_DOCUMENT_UPLOADED_IN_THIS_VISIT: 'No Document Uploaded in this Visit',
    VISIT_INFO: 'Visit Info',
    UPLOADED_FILES: 'Uploaded Files',
    MY_PROFILE: 'My Profile',
    CANCEL_EDIT: 'Cancel Edit',
    EDIT_PROFILE: 'Edit Profile',
    SAVE_CHANGES: 'Save Changes',
    NO_ENROLLED_CLASSES_FOUND: 'No Enrolled Classes Found',
    ENROLLED_SUBJECTS_CLASSES: 'Enrolled Subjects & Classes',
    MY_EXAM: 'My Exam',
    MINUTES_B: ' minutes',
    MINUTE_B: ' minute',
    NO_MORE_ATTEMPTS: 'No More Attempts',
    RESUME: 'Resume',
    START_EXAM: 'Start Exam',
    VIEW_ATTEMPTS: 'View Attempts',
    NO_EXAM_FOUND: 'No Exam Found',
    EXAM_FIRST_DP: '1. This exam will lasted for ',
    EXAM_SECOND_DP: '2. After starting the exam, you can return to continue the exam if you accidently leave the exam page ',
    EXAM_SECOND_DP_CONTD: '(note that the timer will continue to count until the end of the exam time)',
    EXAM_THIRD_DP: '3. Failure to submit the exam on time will be considered ',
    ATTEMPT_HISTORY: 'Attempt History',
    TOTAL_ATTEMPTS: 'Total Attempts',
    SORT_BY: 'Sort By',
    LATEST: 'Latest',
    EARLIEST: 'Earliest',
    EXAM_DATE: 'Exam Date',
    EXAM_CODE: 'Exam Code',
    READ_CAREFULLY: 'Please read carefully before start the exam',
    CONFIRM_START: 'Confirm Start',
    START_NOW: 'Start Now',
    LOADING_EXAM: 'Loading Exam',
    TIME_LEFT: 'Time Left',
    MINS_B: ' mins',
    SUBMIT_EXAM: 'Submit Exam',
    SUBMIT_EXAM_CHECK: 'Are you sure you want to submit your exam now?',
    QUESTIONS_NOT_ANSWERED: 'Below questions has not answered yet!',
    SUBMIT_NOW: 'Submit Now',
    MY_ATTEMPT: 'My Attempt',
    EXAM_SINGLE: 'Exam',
    WELL_DONE: 'Well Done!',
    INCORRECT: 'Incorrect!',
    PARTIAL_CORRECT: 'Partial Correct!',
    MARKS_B: ' Marks',
    MARKS_A: 'Marks',
    ENROLL_FORM: 'Enroll Form'
  }
};



