/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class ExamStore {

  loading: boolean = false;

  examList: any = { totalCount: 0, data: [] };

  attempId: number = 0;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      examList: observable,
      attempId: observable,
      getExamList: action,
      startExam: action,
      resumeExam: action,
      submitExam: action,
      getStudentExamResponseDetailByAttemptId: action,
    });

    this.rootStore = rootStore;
  }

  getExamList = async (take: number, skip: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Exam/GetExamList/${take}/${skip}`);
      this.examList = res.data;
      console.log("Exam List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  startExam = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Exam/StartExam/${id}`);
      this.attempId = res.data.attemptId;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  resumeExam = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Exam/ResumeExam`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  submitExam = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Exam/SubmitExam`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getStudentExamResponseDetailByAttemptId = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Exam/GetStudentExamResponseDetailByAttemptId/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
