
const login = {
  SIGN_IN: '登錄',
  SIGN_IN_NOW: '登錄界面',
  WELCOME_TO_THE_SYSTEM: '歡迎來到學校管理系統',
  FORGOT_PASSWORD: '忘記密碼',
  USERNAME: '用戶名',
  PASSWORD: '密碼',
  CONFIRM_PASSWORD: '確認您的密碼',
  TWO_PASSWORDS_NOT_MATCH: '已輸入密碼不匹配',
  INCORRECT_USERNAME_PASSWORD: '用戶名/密碼錯誤',
  EMAIL: '電郵',
  CONFIRM_EMAIL: '確認您的電郵',
  TWO_EMAILS_NOT_MATCH: '已輸入電電郵不匹配',
  INVALID_EMAIL: '此電郵地址無效',
  SEND_VERIFICATION_EMAIL: '發送驗證郵件',
  VERIFICATION_EMAIL_SENT: '驗證郵件已發送',
  RESET_PASSWORD: '重置密碼',
  RESET_YOUR_PASSWORD: '重置您的密碼',
  RESET_PASSWORD_FAILED: '重置密碼失敗',
  VERIFY_URL_LINK: '驗證URL鏈接',
  SIGN_OUT: '登出',
  SIGN_OUT_CHECK: '您確定現在登出嗎',
  VALIDATING_USER_CREDENTIAL: '驗證身份信息中',
  BYE: '再會',
  WELCOME_TO_AUOKKA: '歡迎來到Auokka教育管理系統',
  EMAIL_NOT_EXIST: '此郵箱地址不存在',
  RESET_PASSWORD_NOW: '重置密碼',
  NEW_PASSWORD: '新密碼',
  RESET_NOW: '確認重置',
  YOUR_EMAIL: '你的郵箱',
  SEND_EMAIL: '發送郵件',
  BACK_TO_LOGIN: '返回登錄',
  ENTER_YOUR_EMAIL: '輸入你的賬號郵箱來獲取重置密碼郵件',
}

const menu = {
  HI: "你好",
  DASHBOARD: '儀表板',
  STUDENT: '學生管理',
  TEACHER: '老師管理',
  ACADEMIC: '科目配置',
  QUESTION: '考題分配',
  EXAM: '考試系統',
  CALENDAR: '日期設置',
  GROUP: '集團分配',
  SUBJECT: '課程',
  SUBJECT_CLASS: '班級',
  DOMAIN: '科目',
  QUESTION_TYPE: '題目類型',
  QUESTION_CATEGORY: '課題類別',
  KNOWLEDEGE_POINT: '知識點',
  OVERVIEW: '概覽',
  ENROLLMENT: '課程註冊',
  EXAM_SETTING: '考試設置',
  EXAM_QUESTIONS: '考題',
  ORGANISATION: '教育機構',
  VISIT_LIST: '授課列表',
}

const dashboard = {
  MSG_LIST: '系統消息列表',
  TYPE: '類別',
  DESCRIPTION: '詳情',
  SENDER: '發件人',
  RECEIVER: '收件人',
  SENT_TIME: '發送時間',
  ACTION: '操作',
  VIEW_DETAIL: '瀏覽詳情',
  MSG_DETAIL: '消息詳情',
  STUDENTS: '學生',
  TEACHERS: '老師',
  ORGANISATIONS: '教育機構',
  TEACHER_VISITS: '老師約課數',
  ORGANISATION_VISITS: '機構約課數',
  LAST_YEAR: '去年',
}

const yearLevel = {
  MODULE_B: '有關',
  LIST_B: '列表',
  ADD_A: '新增',
  DESCRIPTION_B: '詳情',
  UPDATE_A: '更新',
  CONFIRM_A: '確認',
  CREATION: '新增',
  UPDATE_NOW: '更新',
  CREATE_NOW: '新增',
  DELETE_A: '刪除',
  CONFIRMATION: '确认',
  CONFIRM_DELETE: '确认刪除',
  DELETE_NOW: '刪除',
  DELETE_CHECK: '你確認刪除此條記錄',
  DELETE_INFO_YEAR_LEVEL: '所有與此條記錄有關數據（如課程，科目等等）將會一併刪除',
  EDIT: '編輯',
  UPDATE: '更新',
}

const subject = {
  SUBJECT_CODE: '課程代號',
  SETTING_B: '設置',
  NOT_SELECTED: '未選擇',
  DELETE_INFO_SUBJECT: '所有與此條記錄有關數據（如班級等等）將會一併刪除'
}

const subjectClass = {
  CLASS_CODE: '班號',
  CLASS_IDENTIFIER: '班級ID',
  DELETE_INFO_SUBJECT_CLASS: '所有與此條記錄有關數據將會一併刪除'
}

const domain = {
  CODE_B: '號',
  DELETE_INFO_DOMAIN: '所有與此條記錄有關數據（如課程等等）將會一併刪除',
  LINK_SUBJECTS: '鏈接課程',
  LINK_DOMAIN_SUBJECT: '鏈接科目與課程',
  STEP_1_SELECT: '第一步：從下方列表選擇課程',
  STEP_2_EDIT_SELECT: "第二步：編輯已選擇的課程必修狀態",
  LINK_CONFIRM: '確認鏈接',
  LINK_NOW: '鏈接',
  TOGGLE_COMPULSORY_STATUS: '切換課程必修狀態（開啟 = 必修課， 關閉 = 非必修課）',
}

const question = {
  OVERVIEW_B: '總覽',
  CATEGORY: '課題類別',
  DIFFICULTY: '難度',
  VIEW_OPTIONS: '查看答案選項',
  QUESTION_TYPE: '題目類型',
  NO_QUESTION_CAT_FOUND: '暫無課題類別',
  NO_KNW_PT_FOUND: '暫無知識點',
  DELETE_INFO_QUESTION: '所有與此條記錄有關數據（如學生答案和分數等等）將會一併刪除',
  DETAIL: '詳情',
  QUESTION_OPTIONS: '答案選項',
  OPTION: '選項',
  OPTION_DETAIL: '選項詳情',
  IS_CORRECT: '是否為正確答案',
  CORRECT_ANSWER: '正確答案',
  DELETE_INFO_EXAM: '所有與此條記錄有關數據（如測驗記錄等等）將會一併刪除',
  SEARCH_SUBJECT: '搜索課程',
  SUBJECT_COUNT: '課程數量',
}

const questionType = {
  TYPE: '類別',
  DELETE_INFO_QUESTION_TYPE: '所有與此條記錄有關數據（如所屬題目等等）將會一併刪除'
}

const questionCat = {
  QUESTION_CATEGORY: '課題類別',
  DELETE_INFO_QUESTION_CAT: '所有與此條記錄有關數據（如所屬題目等等）將會一併刪除',
}

const knowledgePoint = {
  DELETE_INFO_KNOWLEDGE_PT: '所有與此條記錄有關數據（如所屬題目等等）將會一併刪除',
}

const exam = {
  START_TIME: '開始時間',
  END_TIME: '結束時間',
  SETTING: '設置',
  TIME_LIMIT: '時限',
  DISPLAY_KNOWLEGE_PT: '測驗中顯示知識點',
  ENABLE_PARTIAL_MARK_IN_MP: '多選題允許計算部分分數',
  ENABLE_SAME_QUESTION_EXAM: '允許重複題目',
  INFINITE_ATTEMPTS_ALLOWED: '允許無限次嘗試',
  ATTEMPT_ALLOW: '測驗嘗試次數',
  DELETE_INFO_EXAM: '所有與此條記錄有關數據將會一併刪除',
  DELETE: '刪除',
}

const examQuestions = {
  SETTING_A: '設置',
  MARK_B: '分',
  MARKS: '分',
  MARK: '分數',
  QUANTITY: '數量',
  NO_SETTING_FOUND: '無有關設定',
  EDIT_QUESTIONS: '編輯考題',
  QUESTION_REPO_FOR_EXAM: '題庫: ',
  SAVE_CONFIRM: '確認保存',
  CHANGE_SAVE: '更改保存',
  NUMBER_SELECTED_QUESTIONS_EQUAL_TO_QUANTITY_CHECK: '已選題數必須與設定題目數量吻合',
  PLS_ADJUST_QUANTITY: '請調整已選題數，或在考試系統配置頁面調整已設題目數量。',
  QUESTIONS: '考題',
  SELECTED_B: '已選',
}

const examSetting = {
  MANAGE_STUDENT_ACCESS: '學生測試訪問權管理',
  GENERATE_QUESTIONS: '生成考題',
  GENERATED_QUESTIONS: '已生成考題',
  MARK_PER_QUESTION: '每題分數',
  DELETE_INFO_EXAM_SETTING: '所有與此條記錄有關數據（如有關測驗等等）將會一併刪除',
  STUDENT_ACCESS: '學生測試訪問',
  SELECT_ALL: '勾選所有',
  UNSELECT_ALL: '去除所有勾選',
  STUDENTS_B: '學生',
  STUDENT_B: '學生',
}

const teacher = {
  TEACHER_PROFILE: '老師檔案',
  BOD: '出生日期',
  TITLE: '頭銜',
  MIDDLE_NAME: '中間名',
  USER_ROLE: '用戶類別',
  DELETE_INFO_TEACHER: '所有與此條記錄有關數據（如所屬班級等等）將會一併刪除',
  NAME: '姓名',
}

const student = {
  STUDENT_PROFILE: '學生檔案',
  DELETE_INFO_STUDENT: '所有與此條記錄有關數據（如所屬班級等等）將會一併刪除',
  ENROLLMENT: '註冊',
  STUDENT_SINGLE: '學生',
  ENROLL_STUDENT_SEMESTER: '根據學生註冊學期',
  ENROLLED_SEMESTERS: '已註冊學期',
  VIEW_CLASS: '查看班級',
  DELETE_INFO_ENROLLMENT: '所有與此條記錄有關數據（如有關學生等等）將會一併刪除',
  STUDENT_SEMESTER: '學期註冊',
  ENROLLED_CLASSES: '已註冊班級',
  ENROLL_CLASS: '註冊班級',
  NO_ENROLLMENTS_FOUND: '無註冊記錄',
  ENROLLED_COURSE_INFO: '已註冊課程信息',
  REMOVE: '移除',
  UNIT_CODE: '科目號',
  COMPULSORY: '必修課',
  SUBJECT_IS_ENROLLED: '此課程已經註冊',
  ADD_NOW: '新增',
  DELETE_INFO_SUBJECT_ENROLLMENT: '所有與此條記錄有關數據（如已註冊班級等等）將會一併刪除',
  NO_RESULT_FOUND: '無數據',
  NO_STUDENT_SELECTED: '未選擇學生',
  NO_SUBJECT_FOUND: '未發現有關課程',
}

const calendar = {
  YEAR: '學年',
  SCHOOL_YEAR: '學年',
  VIEW_SEMESTERS: '查看學期（大）',
  START_DATE: '起始日期',
  END_DATE: '結束日期',
  SCHOOL_SEMESTER: '學期（大）',
  SEMESTER: '學期（大）',
  VIEW_TERMS: '查看學期（小）',
  SCHOOL_TERM: '學期（小）',
  TERM: '學期（小）',
  VIEW_TERM_DATE: '查看學期（小）日期',
  SCHOOL_TERM_DATES: '學期（小）日期',
  TERM_DATE: '学期（小）日期',
  SEMESTER_INT: '大學期（大）數字編號',
  TERM_INT: '學期（小）數字編號',
}

const group = {
  ORGANISATION: '教育機構',
  ORGANISATION_NAME: '教育機構名',
  VIEW_STUDENTS: '查看學生',
  VIEW_VISITS: '查看約課',
  STUDENT_IN_ORGANISATION: '教育機構學生',
  VISIT: '約課',
  VISITS: '約課',
  VISIT_NAME: '約課名稱',
  UPDATED_TIME: '更新時間',
  ADDRESS: '地址',
  TEL: '電話號碼',
  MOBILE: '手機號碼',
  LONGITUDE: '經度',
  LATITUDE: '緯度',
  CONTACT_PERSON: '聯繫人',
  STUDENT_LIST: '學生列表',
  STUDENTS_SINGLE: '學生',
  TEACHER_SINGLE: '老師',
  SELECT_STUDENT: '選擇學生',
  NO_STUDENTS_FOUND_PLEASE_ADD_STUDENT_ORG: '未找到學生，請優先將學生添加進相應組織！',
  CLEAR: '清除',
  TIME_START_IN: '起始時間於',
  OVER_8_HOURS_WARNING: '請注意，以上約課時長已超過8小時！',
  ENABLE_RECURRING_VISIT: '約課循環創建模式開啟',
  SKIP_WEEKENDS: '跳過週末',
  RECURRENCE: '循環模式',
  DAILY: '每日',
  WEEKLY: '每週',
  MONTHLY: '每月',
  REPEAT_FOR: '循環',
  TIMES: '次',
  OF_EVERY: '每隔',
  DAY_S: '天',
  WEEK_S: '週',
  MONTH_S: '月',
  OVERWRITE_WARNING: '提示：批量創建新約課時間將會替代之前已創建的約課時間列表。',
  GENERATE_TIME_SLOTS: '創建新約課時間列表',
  NO_TIME_SLOTS: '目前無創建約課時間！',
  UPDATE_VISIT: '更新約課信息',
  CREATE_VISIT: '創建約課信息',
  SCHEDULE_TIME_SLOTS: '安排約課時間',
  TIME_SLOTS: '約課時間列表',
  GROUP_NAME: '集團名',
  VIEW_ORG: '查看組織',
  DELETE_INFO_GROUP: '所有與此條記錄有關數據（如已創建組織等等）將會一併刪除',
  VISITS_BY_ORG: '按組織搜索有關約課信息',
  CLEAR_ALL_MAKER: '關閉所有地圖標識展開',
  APPLY: '應用',
  VIEW: '查看',
  CREATE_TIME: '創建時間',
  STAFF_NAME: '授課人員',
}

const notify = {
  UPDATED_B: '已更新',
  CREATED_B: '已創建',
  DELETED_B: '已刪除',
  UPDATE_FAILED: '更新失敗！請諮詢網絡管理員了解問題',
  CREATE_FAILED: '新增失敗！請諮詢網絡管理員了解問題',
  DELETE_FAILED: '刪除失敗！請諮詢網絡管理員了解問題',
  NO_CLASS_SELECTED_FOR_AT_LEAST_ONE_SUBJECT: '已發現至少一門學科沒有選擇相應班級',
  NO_SUBJECTS_IS_FOUND_IN_SELECTED_DOMAIN: '已選科目當前無任何可註冊課程',
  VERIFICATION_EMAIL_SENT_FAILED: '驗證郵件發送失敗！請諮詢網絡管理員了解問題',
}

export default {
  translation: {
    ...login,
    ...menu,
    ...dashboard,
    ...yearLevel,
    ...subject,
    ...subjectClass,
    ...domain,
    ...question,
    ...questionType,
    ...questionCat,
    ...knowledgePoint,
    ...exam,
    ...examQuestions,
    ...examSetting,
    ...teacher,
    ...student,
    ...calendar,
    ...group,
    ...notify,
    lang: 'zh-TW',
    GO_BACK: '返回',
    WELCOME: '您好',
    CANCEL: '取消',
    PLEASE_CONTACT_ADMIN: '請聯繫系統管理員',
    SHOW_ALL_SUBJECTS: '顯示所有科目',
    SHOW_ONLY_ENGLISH_MATH: '僅顯示英語/數學',
    YEAR_LEVEL: '年級',
    FIRST_NAME: '名',
    LAST_NAME: '姓',
    SURNAME: '姓',
    ERROR: '失敗',
    PLEASE_FILL_IN_ALL_FIELDS: '請確保所有空格處已填寫',
    NO: '否',
    YES: '是',
    TODAY: '今天',
    PREV: '往前',
    NEXT: '往後',
    ABSENT_BETWEEN: '缺席於',
    CURRENT: '當前',
    REMINDER: '通知欄',
    VIEW_ALL: '查看全部',
    VISIT_HISTORY: '約課歷史記錄',
    NO_HISTORY_FOUND: '無記錄',
    NO_MARKED: '未評分',
    NO_FEEDBACK_YET: '未反饋',
    NO_DOCUMENT_UPLOADED_IN_THIS_VISIT: '當前無上傳文件',
    VISIT_INFO: '詳情',
    UPLOADED_FILES: '已上傳文件',
    MY_PROFILE: '我的檔案',
    CANCEL_EDIT: '取消編輯',
    EDIT_PROFILE: '編輯檔案',
    SAVE_CHANGES: '保存更改',
    NO_ENROLLED_CLASSES_FOUND: '當前無註冊班級',
    ENROLLED_SUBJECTS_CLASSES: '已註冊課程與班級',
    MY_EXAM: '我的考試',
    MINUTES_B: '分鐘',
    MINUTE_B: '分鐘',
    NO_MORE_ATTEMPTS: '無剩余嘗試機會',
    RESUME: '恢復考試',
    START_EXAM: '開始考試',
    VIEW_ATTEMPTS: '瀏覽考試記錄',
    NO_EXAM_FOUND: '無考試',
    EXAM_FIRST_DP: '1. 此考試總體時長為',
    EXAM_SECOND_DP: '2. 當考試已經開始，如果意外離開了考試頁面，你可以隨時點擊恢復考試按鈕返回考試',
    EXAM_SECOND_DP_CONTD: '(請注意，考試計時器在考試期間會持續計時直到考試結束，期間包括學生中途離開考試頁面)',
    EXAM_THIRD_DP: '3. 如若學生未在規定時間提交試卷將會被視為',
    ATTEMPT_HISTORY: '考試記錄',
    TOTAL_ATTEMPTS: '總共嘗試次數',
    SORT_BY: '排序',
    LATEST: '最近',
    EARLIEST: '最早',
    EXAM_DATE: '考試日期',
    EXAM_CODE: '考試號',
    READ_CAREFULLY: '請在開始考試前仔細閱讀以下條款',
    CONFIRM_START: '確認開始',
    START_NOW: '開始考試',
    LOADING_EXAM: '加載試卷中',
    TIME_LEFT: '剩餘時間',
    MINS_B: '分鐘',
    SUBMIT_EXAM: '提交試卷',
    SUBMIT_EXAM_CHECK: '你確認現在提交試卷嗎？',
    QUESTIONS_NOT_ANSWERED: '你還未回答以下題目！',
    SUBMIT_NOW: '現在提交',
    MY_ATTEMPT: '考試回顧',
    EXAM_SINGLE: '考試',
    WELL_DONE: '正確！',
    INCORRECT: '錯誤！',
    PARTIAL_CORRECT: '部分正確！',
    MARKS_B: '分',
    MARKS_A: '成績',
  }
};
