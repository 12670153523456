import { observable, action, makeObservable } from 'mobx';
import ExamStore from './exam-store';
import UserStore from './user-store';
import MessageStore from './message-store';
import EnrollmentStore from './enrollment-store';
import FileStore from './file-store';

interface WebNotification {
  message: string,
  options?:
  {
    variant: 'success' | 'error' | 'info' | 'warning'
  },
}

export default class RootStore {
  testVal = 'TEST_VALUE';

  destination = 'Select';

  notification: WebNotification = {
    message: "",
    options: {
      variant: "info"
    }
  }

  userStore;

  examStore;

  messageStore;

  enrollmentStore;

  fileStore;

  constructor() {
    makeObservable(this, {
      testVal: observable,
      destination: observable,
      notification: observable,
      notify: action,
    });
    this.userStore = new UserStore(this);
    this.examStore = new ExamStore(this);
    this.messageStore = new MessageStore(this);
    this.enrollmentStore = new EnrollmentStore(this);
    this.fileStore = new FileStore(this);
  }

  notify(msg: string, level?: 'success' | 'error' | 'info' | 'warning') {
    if (level) {
      this.notification = {
        message: msg,
        options: {
          variant: level,
        },
      };
    } else {
      this.notification = {
        message: msg,
      };
    }
  }
}
