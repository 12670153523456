/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class EnrollmentStore {

  loading: boolean = false;

  loadingGroup: boolean = false;

  formList: any = [];

  formInfo: any = null;

  groupList: any = [];

  qualificationList: any = [];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingGroup: observable,
      formList: observable,
      formInfo: observable,
      groupList: observable,
      qualificationList: observable,
      createEnrollForm: action,
      submitEnrollForm: action,
      updateEnrollForm: action,
      deleteEnrollForm: action,
      getFormById: action,
      getFormList: action,
      getGroupList: action,
      getQualificationList: action,
    });

    this.rootStore = rootStore;
  }

  createEnrollForm = async () => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/StudentEnroll/CreateEnrollForm`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getFormList = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/StudentEnroll/GetFormList`);
      this.formList = res.data;
      console.log("Enrol form List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  submitEnrollForm = async (formId: number) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/StudentEnroll/SubmitEnrollForm/${formId}`);
      console.log("Submit enrol form", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateEnrollForm = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/StudentEnroll/UpdateForm`, reqModel);
      console.log("Update enrol form", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteEnrollForm = async (formId: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/StudentEnroll/DeleteEnrollForm/${formId}`);
      console.log("Delete enrol form", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getFormById = async (formId: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/StudentEnroll/GetFormById/${formId}`);
      this.formInfo = res.data;
      console.log("Form Info", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getGroupList = async () => {
    this.loadingGroup = true;
    try {
      const res = await Axios.get(`/api/StudentEnroll/GetGroupList`);
      this.groupList = res.data;
      console.log("Group List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingGroup = false;
    }
  }

  getQualificationList = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/StudentEnroll/GetQualificationList`);
      this.qualificationList = res.data;
      console.log("Qualification List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
