import { FileCategory, DocumentType } from "./options";

export const isProduction = process.env.NODE_ENV === 'production';

const prodBackEndUrl = 'https://studentportapi.auokka.com'; // 'https://mp.tour61.com.au'
const prodFrontEndUrl = 'iems.vmortech.com.au'; // 'http://www.tourx.com.au'
const prodPictureBedUrl = 'https://schooladminapi.auokka.com'; // 'http://images.tour61.com.au'


export const backEndBaseURL = isProduction ? prodBackEndUrl : 'https://studentportapi.auokka.com'//'http://localhost:52012'
export const frontEndBaseUrl = isProduction ? prodFrontEndUrl : 'http://localhost:3000';
export const imageURLPrefix = isProduction ? prodPictureBedUrl : 'http://172.20.10.6:45455';
export const fileEndBaseUrl = isProduction ? prodPictureBedUrl : 'https://schooladminapi.auokka.com';
export const USIBackendURL = "https://6d78-165-228-212-131.au.ngrok.io"

export const constructImgUrl = (type: DocumentType, category: FileCategory, id: number, name: string) => {
    return `${fileEndBaseUrl}/api/file/getFile/${type}/${category}/${id}/${name}`;
}

export const OrgCodeForUSI = "VA1803";